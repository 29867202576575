<template>
	<el-scrollbar wrapClass="scrollbar-wrapper">
		<el-menu mode="vertical" :show-timeout="200" :collapse="isCollapse" theme="dark" :default-active="$route.path"
		 :unique-opened="true" text-color="#C0C2C5" active-text-color="#FF6723">
			<!-- <div class="sideBarLogo title-no-border">
				<img :src="sideBarLogo" alt="Cel.ro Marketplace">
				<div class="logoText-big">
					<span class="primary">market</span>place</div>
				<div class="logoText-small">marketplace.cel.ro</div>
			</div> -->
			<sidebar-item :routes="routes"></sidebar-item>
		</el-menu>
	</el-scrollbar>

</template>

<script>
	import {
		mapGetters
	} from 'vuex';

	const SidebarItem = () => import('./SidebarItem');
	import sideBarLogo from '@/assets/images/bulina_89px.png';
	export default {
		components: {
			SidebarItem
		},
		data() {
			return {
				sideBarLogo
			};
		},
		computed: {
			...mapGetters({
				'sidebar': 'app/sidebar'
			}),
			routes() {
				return this.$router.options.routes;
			},
			isCollapse() {
				return !this.sidebar.opened;
			},
			isDeviceMobile() {
				return this.$store.state.app.device === 'mobile';
			},
			isSideBarToggled() {
				return this.$store.state.app.sidebar.opened;
			}
		},
		watch: {
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	@import './src/styles/modules/sidebar.scss';
</style>